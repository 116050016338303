import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'

import GoogleMaps from './GoogleMaps'
import { Phone, Email } from './ContactOptions'

const sitemapLinks = [
  {
    label: 'Anreise',
    to: '/anreise',
  },
  {
    label: 'Impressum',
    to: '/impressum',
  },
  {
    label: 'Datenschutz',
    to: '/datenschutz',
  },
] as {
  label: string
  to: string
}[]

const currentYear = new Date().getFullYear()

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white text-center md:text-left py-10">
      <div className="container">
        <div className="flex flex-wrap">
          <div className="w-full mb-5 md:w-4/12 md:px-2 ">
            <h4>Über Uns</h4>
            <hr className="my-2 mx-auto md:mx-0" />
            <p>
              Den Stadlwirt gibt es schon seit 1990, Walter und seine Frau Rosi
              betreiben ihn mit Herz und Freude. Sie sorgen für ihre Gäste im
              wunderschönen Nauders auf 1400m Seehöhe.
              <br />
              <br />
              Das Stadlwirt-Team freut sich auf Ihren Besuch!
            </p>
          </div>

          <div className="w-full mb-5 md:w-3/12 md:px-2 ">
            <h4>Kontakt</h4>
            <hr className="my-2 mx-auto md:mx-0" />
            <address>
              <strong> Restaurant Stadlwirt </strong>
              <br />
              Walter und Rosi Lutz <br />
              Kreuzgasse 132 <br />
              6543 Nauders <br />
              <Phone className="golden hover:text-orange-400" />
              <br />
              <Email className="golden hover:text-orange-400" />
            </address>
          </div>

          <div className="w-full mb-5 md:w-5/12 md:px-2 ">
            <GoogleMaps query="Restaurant+Stadlwirt+Nauders" zoomLevel={8} />
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="w-full my-2 md:w-5/12 md:px-2 md:order-2">
            <a
              href="https://www.nauders.com/de"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/uploads/logos/nauders-logo.png"
                alt="Nauders Logo"
                className="inline mr-2"
                style={{ maxHeight: '60px' }}
              />
            </a>
            <a
              href="https://www.tirol.at/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/uploads/logos/tirol-logo.jpg"
                alt="Tirol Logo"
                className="inline"
                style={{ maxHeight: '40px' }}
              />
            </a>
          </div>
          <div className="w-full my-2 md:w-7/12 md:px-2 md:order-1">
            &copy; {currentYear} Stadlwirt
          </div>
          <div className="w-full my-2 md:w-7/12 md:px-2 md:order-1">
            <a
              href="https://www.facebook.com/Stadlwirt-in-Nauders-102568194803174/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} size="2x" />
            </a>
          </div>
        </div>
        <div className="flex">
          <div className="w-full my-2 md:px-2">
            {sitemapLinks.map((sitemapLink) => {
              const { label, to } = sitemapLink

              return (
                <span key={label} className="mr-3">
                  <Link to={to} className="golden hover:text-orange-400">
                    {label}
                  </Link>
                </span>
              )
            })}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
