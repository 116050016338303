import React from 'react'

import { SEODefaults } from '../components/SEO'
import TopBar from '../components/TopBar'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

import '../styles/global.scss'

const Layout = ({ children }: Props) => {
  return (
    <div className="antialiased text-gray-900 bg-gray-200">
      <SEODefaults />
      <TopBar />
      <Navbar />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

interface Props {
  children: React.ReactNode
}

export default Layout
