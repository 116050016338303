import { graphql, useStaticQuery } from 'gatsby'

export interface Configuration {
  message: string
  phone: string
  openingHours: string
}

const useConfiguration = () => {
  const {
    markdownRemark: { frontmatter: configuration },
  } = useStaticQuery(
    graphql`
      query {
        markdownRemark(fields: { slug: { eq: "/global/" } }) {
          frontmatter {
            message
            phone
            openingHours
          }
        }
      }
    `,
  ) as { markdownRemark: { frontmatter: Configuration } }

  return configuration
}

export default useConfiguration
