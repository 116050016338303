import React from 'react'
import { Link } from 'gatsby'

import useToggle from '../hooks/useToggle'

const navLinks = [
  {
    label: 'Restaurant',
    to: '/restaurant',
  },
  {
    label: 'Wohnungen',
    to: '/wohnungen',
  },
  {
    label: 'Presse',
    to: '/presse',
  },
  {
    label: 'Nauders',
    to: '/nauders',
  },
  {
    label: 'Frivissa',
    to: '/frivissa',
  },
  {
    label: 'Medien',
    to: '/medien',
  },
]

const NavLink = ({
  to,
  isLastElement,
  isOpen,
  toggleIsOpen,
  children,
}: NavLinkProps) => {
  const onClick = () => {
    if (isOpen) toggleIsOpen()
  }

  return (
    <Link
      to={to}
      onClick={onClick}
      className={`block mt-4 lg:inline-block lg:mt-0 text-white hover:underline ${
        isLastElement ? '' : 'mr-4'
      }`}
    >
      {children}
    </Link>
  )
}

interface NavLinkProps {
  to: string
  isLastElement: boolean
  isOpen: boolean
  toggleIsOpen: () => void
  children: React.ReactNode
}

const Navbar = () => {
  const [isOpen, toggleIsOpen] = useToggle()

  return (
    <div className="bg-stadlwirt-red">
      <nav className="flex items-center justify-between flex-wrap p-6 container mx-auto">
        <Link to="/" className="hover:no-underline">
          <div className="flex items-center flex-shrink-0 text-white mr-6">
            <span className="font-semibold text-xl tracking-tight">
              Stadlwirt
            </span>
          </div>
        </Link>
        <div className="block lg:hidden">
          <button
            onClick={toggleIsOpen}
            className="flex items-center px-3 py-2 border rounded text-white border-white hover:text-white hover:border-white"
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div
          className={`w-full flex-grow lg:flex lg:items-center lg:w-auto  ${
            isOpen ? 'block' : 'hidden'
          }`}
        >
          <div className="text-sm lg:flex-grow">
            {navLinks.map((navLink, i) => {
              const { label, to } = navLink

              const isLastElement = i === navLinks.length - 1

              return (
                <NavLink
                  key={label}
                  to={to}
                  isLastElement={isLastElement}
                  isOpen={isOpen}
                  toggleIsOpen={toggleIsOpen}
                >
                  {label}
                </NavLink>
              )
            })}
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Navbar
