import React from 'react'
import { Phone } from './ContactOptions'

import styles from './TopBar.module.scss'
import useConfiguration from '../hooks/cms/useConfiguration'

const TopBar = () => {
  const { message, openingHours } = useConfiguration()
  return (
    <div className={styles.wrapper}>
      <div className="container mx-auto py-1">
        <div className={styles.content}>
          <span className="mr-4">
            <Phone />
          </span>
          <span>{openingHours}</span>
        </div>
        {message && (
          <div>
            <span className={styles.content}>{message}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default TopBar
