import React from 'react'
import Iframe from 'react-iframe'

import styles from './GoogleMaps.module.scss'

const GATSBY_GMAPS_API_KEY = process.env.GATSBY_GMAPS_API_KEY

const GoogleMaps = ({ query, zoomLevel = 15 }: Props) => {
  const url =
    'https://www.google.com/maps/embed/v1/place' +
    `?key=${GATSBY_GMAPS_API_KEY}` +
    `&q=${query}` +
    `&zoom=${zoomLevel}`
  return (
    <div className={styles.wrapper}>
      <Iframe
        url={url}
        width="600"
        height="450"
        frameBorder={0}
        allowFullScreen
      />
    </div>
  )
}

interface Props {
  query: string
  zoomLevel?: number
}

export default GoogleMaps
