// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-anreise-tsx": () => import("./../../../src/pages/anreise.tsx" /* webpackChunkName: "component---src-pages-anreise-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-frivissa-tsx": () => import("./../../../src/pages/frivissa.tsx" /* webpackChunkName: "component---src-pages-frivissa-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-medien-tsx": () => import("./../../../src/pages/medien.tsx" /* webpackChunkName: "component---src-pages-medien-tsx" */),
  "component---src-pages-nauders-tsx": () => import("./../../../src/pages/nauders.tsx" /* webpackChunkName: "component---src-pages-nauders-tsx" */),
  "component---src-pages-presse-tsx": () => import("./../../../src/pages/presse.tsx" /* webpackChunkName: "component---src-pages-presse-tsx" */),
  "component---src-pages-restaurant-tsx": () => import("./../../../src/pages/restaurant.tsx" /* webpackChunkName: "component---src-pages-restaurant-tsx" */),
  "component---src-pages-wohnungen-tsx": () => import("./../../../src/pages/wohnungen.tsx" /* webpackChunkName: "component---src-pages-wohnungen-tsx" */),
  "component---src-templates-apartment-template-tsx": () => import("./../../../src/templates/ApartmentTemplate.tsx" /* webpackChunkName: "component---src-templates-apartment-template-tsx" */)
}

