import React from 'react'

import removeWhiteSpace from '../utils/removeWhiteSpace'
import useConfiguration from '../hooks/cms/useConfiguration'

const email = 'lutz@stadlwirt.at'

export const Phone = (props: Props) => {
  const { phone } = useConfiguration()
  return (
    <a href={`tel:${removeWhiteSpace(phone)}`} {...props}>
      {phone}
    </a>
  )
}

export const Email = (props: Props) => {
  return (
    <a href={`mailto:${email}`} {...props}>
      {email}
    </a>
  )
}

interface Props {
  className?: string
}
